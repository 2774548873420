/* JavaScript for UpdatesModule */

// Export the class itself
export class UpdatesModule {
  constructor(element) {
    this.element = element;
    this.links = this.element.querySelectorAll(".updates-module-updates-wrapper a");
    if (this.links) {
      this.links.forEach(function(link){
        jQuery(link).hover((e) => {
          jQuery('.updates-module-image-wrapper').html(`<img id='updates-module-image' src=${jQuery(e.currentTarget).data('image')} />`);
        }, () => {
          jQuery('.updates-module-image-wrapper').html('');
        });
      });
    }
  }
}

// Exports an array of all the current instances
export const updatesModules = {
  current: [],
};

// Export an init function that looks for and instantiates the module on pageload
export const init = () => {
  // Initialize any instances of the UpdatesModule on any given page
  document.addEventListener("turbolinks:load", () => {
    updatesModules.current = [
      ...document.querySelectorAll(".js-updates-module"),
    ].map((instance) => new UpdatesModule(instance));
  });
};